import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

import VacuformVisualizationGIF from "../images/blog/VacuformVisualization.gif";
import ThermoformingGIF from "../images/blog/Thermoforming_animation.gif";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Your Guide To Plastic Vacuum Forming | Painted Rhino"
            description="From the food you buy at the store to the car you drive or the bus you ride, items created by plastic vacuum forming are everywhere you look."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Your Guide To Plastic Vacuum Forming"
            date="November 15, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     From the food you buy at the store to the car you drive, items created with plastic vacuum forming techniques are everywhere you
                     look. Here's a quick guide to the manufacturing process that creates everyday items and so much more. 
                  </p>
                  <h2>About Vacuum Forming</h2>
                  <p>
                     <a href="/vacuum-forming-thermoforming-company/">Vacuum forming</a> is a manufacturing technique where a sheet of plastic is
                     heated to a temperature that makes it pliable. Then vacuum pressure is used to force the plastic into or over a form so it takes
                     the shape of the mold.
                  </p>
                  <p>
                     The advantages of vacuum forming are that it’s cost-effective and the process has a quick turnaround time. For example, it’s a
                     great way to create large quantities of lighter, durable parts at a reasonable cost.
                  </p>
                  <img src={VacuformVisualizationGIF} />
                  <div className="text-sm mb-8">
                     Credit: By Colagor - Own work, <a href="https://commons.wikimedia.org/w/index.php?curid=9775174">Public Domain</a>
                  </div>
                  <h2>Heavy Gauge Thermoforming</h2>
                  <p>
                     Heavy gauge thermoforming is a related process used for thicker materials. Here at Painted Rhino, we have plastic forming
                     machines that cut large thermoplastic material and then move it through heating stations to prepare for molding. Any drilling or
                     assembly (or other operations) for detail is performed during a secondary process. Vacuum forming, as well as pressure forming
                     and drape forming, are techniques used in this process.
                  </p>
                  <p>
                     The advantages are that large plastic parts, even ones that are more intricate, can be made more quickly, and are more
                     cost-effective to produce.
                  </p>
                  <p>Some of the typical materials used in heavy gauge thermoforming include acrylic, ABS, FR, HIPS, HDPE, and polycarbonates.</p>
                  <img src={ThermoformingGIF} />
                  <div className="text-sm mb-8">
                     Credit:{" "}
                     <a href="https://commons.wikimedia.org/wiki/File:Thermoforming_animation.gif">
                        https://commons.wikimedia.org/wiki/File:Thermoforming_animation.gif
                     </a>
                  </div>
                   <h2>Applications</h2>
                  <p>You can find examples of vacuum forming and thermoforming pretty much wherever you look.</p>
                  <p>
                     In vehicles, for example, the plastic door panels and dashboard are created using these techniques, as are truck beds and
                     bumpers. 
                  </p>
                  <p>
                     When you go to the grocery store, you’ll see plenty of examples in food packaging. Vacuum forming is one of the best ways to keep
                     food fresh, free of contamination, and prevent it from getting damaged before it reaches your refrigerator. It’s also used to
                     package everything from beauty products to baby items.
                  </p>
                  <p>
                     There are also many uses in the medical field, such as packaging health items (like trays that hold medication) or creating hard
                     enclosures around medical devices. 
                  </p>
                  <p>And there are also many larger, industrial applications such as producing:</p>
                  <ul>
                     <li>Aircraft parts</li>
                     <li>Construction equipment</li>
                     <li>Heavy truck components</li>
                     <li>Railcar/bus interiors (such as seats, paneling, and trim)</li>
                     <li>Foodservice components</li>
                     <li>Agricultural equipment and components</li>
                  </ul>
                  <h2>Have An Idea?</h2>
                  <p>
                     At Painted Rhino, we’re experts in bringing concepts to life, not only using plastic vacuum forming and thermoforming
                     manufacturing techniques but in other areas such as fiberglass and composite parts manufacturing. Here’s what our customers{" "}
                     <a href="/reviews/">have to say about our work</a>:
                  </p>
                  <i>
                     <p>
                        “We decided to use Painted Rhino versus other FRP suppliers because they had the ability to actually design and draw out our
                        front cap before we spent the money to sculpt it. The other companies couldn't offer us this service.” - Terry, Skyline
                        Trailers
                     </p>
                  </i>
                  <i>
                     <p>
                        "Painted Rhino is where dreams are made. They took my sketches and improved upon them and sculpted me the fiberglass
                        components on my truck that allowed me to win the Ford design award at SEMA." - Kris
                     </p>
                  </i>
                  <i>
                     <p>
                        "Painted Rhino has worked on several projects of mine. Their team is always on the ball and helping with slight design tweaks
                        to make things easier. Having a vendor that listens is very rare these days. It's a welcome change to have Painted Rhino help
                        me out." - Scott G., Yelp
                     </p>
                  </i>
                  <p>
                     Want to see how we can bring <i>your</i> concept to life? Tell us what you’re dreaming about and we’ll give you a quote.{" "}
                     <a data-modal-open="modal-contact">Contact Painted Rhino today!</a>
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Painted-Rhino-Vacuum-Forming-&-Thermoforming.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 833) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
